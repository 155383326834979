import { Badge } from 'src/enum/Badge'
import { getMetafield } from 'src/functions/util/getMetafield'

import type { Product } from 'src/data/graphql-generated'

export const getBadgeVariant = (product: Product, forProductCard?: boolean): Badge | null => {
  const badge = getMetafield(product.metafields, 'product', 'badge') as Badge | null

  if (!badge) return null
  if (badge === Badge.freeShipping && !forProductCard) return null

  return badge
}
