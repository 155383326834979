/* eslint-disable camelcase */
import { Badge as MantineBadge } from '@mantine/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Badge } from 'src/enum/Badge'

import styles from './ProductBadge.module.scss'

interface ProductBadgeProps {
  badgeVariant: Badge | null
  insideCard?: boolean
}

export const ProductBadge = ({ badgeVariant, insideCard = true }: ProductBadgeProps) => {
  const { t } = useTranslation()

  if (!badgeVariant) return null

  const badgeMap = {
    [Badge.bestseller]: { style: styles.bestseller, label: 'bestseller' },
    [Badge.new]: { style: styles.new, label: t('common.new') },
    [Badge.sale]: { style: styles.sale, label: 'sale' },
    [Badge.freeShipping]: {
      style: styles.freeShipping,
      label: t('common.freeShipping'),
    },
    [Badge.limited]: { style: styles.limited, label: 'limited' },
    [Badge.lastChance]: { style: styles.lastChance, label: t('common.lastChance') },
    [Badge.blackFriday]: { style: styles.blackFriday, label: 'Black Friday' },
    [Badge.percentageSign]: { style: styles.blackFriday, label: '%' },
  }

  const selectedBadge = badgeMap[badgeVariant]

  if (!selectedBadge) return null

  return (
    <MantineBadge
      className={clsx(styles.badge, insideCard && styles.insideCard, selectedBadge?.style)}
      size="lg"
      radius="xl"
      suppressHydrationWarning
    >
      {selectedBadge?.label}
    </MantineBadge>
  )
}
