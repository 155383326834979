export enum Badge {
  bestseller = 'bestseller',
  new = 'new',
  sale = 'sale',
  freeShipping = 'free_shipping',
  limited = 'limited',
  lastChance = 'last_chance',
  blackFriday = 'black_friday',
  percentageSign = 'percentage_sign',
}
